import { useCustomFetch } from '../auth/useCustomFetch'

const validateAccount:any = async () => {
  const { data } = await useCustomFetch<any>('/user/is-admin')
  return data.value
}

// ---cut---
export default defineNuxtRouteMiddleware(async (to, from) => {
  const {isAdmin} = await validateAccount()

  if ( isAdmin === false) {
    return navigateTo('/')
  }
})
